<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ count }} Devices</h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center">
              <div class="input-with-icon mr-3">
                <span class="icon is-small is-left" style="margin-top: 35px;">
                  <i class="fas fa-search"></i>
                </span>
                <input type="search" class="form-control" v-model="searchQuery" @keyup="handleSearch" placeholder="Search" >
              </div>
              <b-button variant="primary" @click="openModal" style="border-radius: 5px">Add Device</b-button>
            </div>
          </template>
          
          <template v-slot:body>
            <b-row v-if="loading">
      <LoadingSpinner :loading="loading" />
    </b-row>
            <b-row v-else>
              <b-col md="12" v-if="!rows.length" class="table-responsive">
                <h5 class="text-center mt-4">{{ response_message }}</h5>
              </b-col>
              <b-col md="12" v-else class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:cell(serialNumber)="data">
                    {{ (currentPage - 1) * itemsPerPage + data.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="text-left">
                      <button fdprocessedid="epulve":disabled="data.item.status === 'scanning'" type="button" title="Scan"
                        class="btn btn btn-success btn-secondary btn-sm"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top: 5px;"><i
                          class="fas fa-search " style="margin-left: 5px !important;"
                          @click="handleButtonClick(data.item.public_id)"></i></button>

                      <b-button :disabled="data.item.status === 'scanning'" variant="info" size="sm"
                        @click="edit(data.item)" title="Edit"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px">
                        <i class="fas fa-pencil-alt" style="margin-left: 5px !important;"></i>
                        <!-- Edit font icon -->
                      </b-button>
                      

                      <!-- <button title="Stop" class="btn btn-success mb-1" fdprocessedid="epulve"><i class="fas fa-stop"></i></button> -->
                      <b-button :disabled="data.item.status === 'scanning'" variant="danger" size="sm"
                        @click="remove(data.item.public_id)" title="Delete"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px">
                        <i class="fas fa-trash-alt" style="margin-left: 5px !important;"></i>
                        <!-- Delete font icon -->
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="onPageChange"
            align="right" class="p-3"  v-if="rows.length" />
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Devices' : 'Add Devices'" ok-title="Save" @ok="submitForm"
      no-close-on-backdrop no-close-on-esc @hide="checkModalClose" :disabled="isSubmitting">
      <b-form @submit.stop.prevent="submitForm" :valid="isFormValid">
        <b-form-group label="Device Type" label-for="deviceInput">
          <b-form-radio-group v-model="newGroup.deviceType">
            <b-form-radio value="single">Single</b-form-radio>
            <b-form-radio value="multiple">Multiple</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group label="Ip Address" label-for="ipInput" v-model="newGroup.ipAddress" :state="ipState"
          invalid-feedback="Ip  is required">
          <b-form-input id="ipInput" v-model="newGroup.ipAddress" style="border-color:lightgray; height:38px;" required
            @keydown="preventLeadingSpace($event, 'ipAddress')" maxlength="20"></b-form-input>
        </b-form-group>

        <b-form-group label="Select Subnet" label-for="subnetIpInput" v-show="showSubnetInput" :state="subnetState"
          invalid-feedback="Subnet is required">
          <b-form-select id="subnetIpInput" v-model="newGroup.selectedSubnet" :options="subnet"
            required></b-form-select>
        </b-form-group>

        <b-form-group label="Group" label-for="locationsInput" :state="groupState"
          invalid-feedback="Group  is required">
          <b-form-select id="locationsInput" v-model="newGroup.selectedGroup" :options="groupsOptions" >
          </b-form-select>
        </b-form-group>
        

        <b-form-group label="Scan Mode" label-for="scanInput">
          <b-form-radio-group name="modeOptions" v-model="newGroup.mode">
            <b-form-radio value="normal">Normal Mode</b-form-radio>
            <b-form-radio value="intense">Intense Mode</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import axios from "axios";
import constant, { decryptToken, response_message, toastMessage } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue";
import { core } from "../../../config/pluginInit";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";

import { BPagination } from "bootstrap-vue";

export default {
  name: "Website",
  components: {
    Treeselect,
    BPagination,
    LoadingSpinner
  },
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      // Table columns definition
      columns: [
        { label: 'S. No.', key: 'serialNumber', class: 'text-left' },
        { label: "Category", key: "category", class: "text-left", sortable: true },
        { label: "Ip Address", key: "ip_address", class: "text-left", sortable: true },
        { label: "Subnet", key: "subnet", class: "text-left", sortable: true },
        { label: "Group", key: "group", class: "text-left", sortable: true },
        { label: "Mode", key: "mode", class: "text-left", sortable: true },
        { label: "Status", key: "status", class: "text-left", sortable: true },
        { label: "Action", key: "action", class: "text-left" },
      ],
      sortBy: '',
      sortDesc: false,
      searchQuery: '',
      preventModalClose: false,
      currentPage: 1,
      itemsPerPage: 20,
      totalRows: 0,
      ipState: null,
      groupState: null,
      subnetState: null,
      typeState: null,
      message: "",
      icon: "",
      countryOptions: [],
      selectedGroup: [],
      count: 0,
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      newGroup: {
        deviceType: "single",
        sensor_ip: "",
        subnet: "",
        groups: "",
        mode: "normal",
      },
      groupsOptions: [],

      subnet: [
        { text: "25", value: "25" },
        { text: "26", value: "26" },
        { text: "27", value: "27" },
        { text: "28", value: "28" },
        { text: "29", value: "29" },
        { text: "30", value: "30" },
      ],
      response_message: response_message,
      types: [
        { text: "Http", value: "Http" },
        { text: "Https", value: "Https" },
      ], // // Initialize the Fail Thresholds array
      loading: true, // Add a loading state
    };
  },
  computed: {
    // Check if the form is valid
    isFormValid() {
      return this.ipState && this.groupState && (this.newGroup.deviceType === "single" || this.newGroup.selectedSubnet);
    },
    showSubnetInput() {
      // Check the value of newDevice.deviceType
      // Return true if 'Multiple', false if 'Single'
      return this.newGroup.deviceType === "multiple"; // Change 'desktop' to whatever value represents 'Multiple' in your radio buttons
    },
  },
  methods: {
    // First letter space not allowed
    preventLeadingSpace(event, key) {
      if (!this.newGroup) {
        this.newGroup = {
          sensor_ip: ""
        };
      }

      if (event.target.selectionStart === 0 && event.keyCode === 32) {
        event.preventDefault();
      }
    },
    handleSearch() {
      // Call fetchData with the searchQuery
      this.fetchData(this.currentPage, this.searchQuery);
      this.loading = true;
    },

    cancelForm() {
      //Form cancle
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },


    async fetchGroupOptions() {
      //Fetch groups 
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token

        const response = await axios.get(
          constant.API_BASE_URL + "vms/groups/",
          {
            headers: {
              Authorization: "Token " + decryptedToken,
            },
          }
        );
        const groups = response.data.data; // Extract the groups array from the response
        const groupsOptions = groups.map((group) => ({
          text: group.name,
          value: group.public_id,
        }));

        this.groupsOptions = groupsOptions;
        this.groupsData = groupsOptions;
      } catch (error) {
        console.error("Error fetching group options:", error);
      }
    },

    async fetchData(pageNumber, searchQuery = '') {
      //Fetch Devices
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token

        const response = await axios.get(
          constant.API_BASE_URL + "vms/instances/", {
          params: {
            page: pageNumber,
            search: searchQuery,
            ordering: '-created_at',
          },
          headers: {
            Authorization: "Token " + decryptedToken,
          },
        }
        );
        this.count = response.data.count
        this.totalRows = response.data.count; 
        if (response.data && Array.isArray(response.data.data)) {
          // Check if data property exists and is an array
          this.rows = response.data.data.map((item) => ({
            public_id: item.public_id,
            created_at: item.created_at,
            ip_address: item.ip_address,
            subnet: item.subnet,
            category: item.category,
            status: item.status,
            mode: item.mode,
            group: item.group_name,
          }));
          this.loading = false;
        } else {
          this.rows = [];
          this.loading = false;
        }
      } catch (error) {
        this.rows = [];
        this.loading = false;
      }
    },

    onPageChange(pageNumber) {
      // Fetch data for the new page
      this.currentPage = pageNumber;
      this.fetchData(pageNumber, this.searchQuery);

    },
    async handleButtonClick(public_id) {
      //Devices Scan
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/scan/?public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        window.location.reload();
      } catch (error) {
        console.error("Error fetching website data:", error);
      }
    },
    async submitForm() {
      // Devices Add and edit
      try {
        this.ipState = this.newGroup.ipAddress ? true : false;
        this.groupState = this.newGroup.selectedGroup ? true : false;
        this.subnetState = this.newGroup.selectedSubnet ? true : false; // Add this line for subnet validation
        if (this.newGroup.deviceType === "single") {
      this.newGroup.selectedSubnet = null;
      this.subnetState = false; // Subnet validation false सेट करें
    }
        if (this.isFormValid) {
          this.isSubmitting = true; // Enable the submit button
          const token = localStorage.getItem("token");
          const key = "enc"; // Fetch encrypted token
          const decryptedToken = decryptToken(token, key); // Decrypt token
          const { deviceType, ipAddress, selectedSubnet, selectedGroup, mode } = this.newGroup;
          const requestData = {
            category: deviceType,
            ip_address: ipAddress,
            subnet: selectedSubnet,
            group: selectedGroup,
            mode: mode,
          };
          const headers = {
            Authorization: "Token " + decryptedToken,
          };
          let response;
          if (this.editMode) {
            // Edit mode
            response = await axios.put(
              constant.API_BASE_URL + "vms/instance/" + this.newGroup.public_id + "/",
              requestData,
              { headers }
            );
          } else {
            // Add mode
            response = await axios.post(
              constant.API_BASE_URL + "vms/instances/",
              requestData,
              { headers }
            );
          }
          this.message = response.data.message;
          // Set the icon to "success" for successful response
          this.icon = "success"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
          await this.fetchData();
          this.modalOpen = false;
          this.clearForm();
        } else {
          this.preventModalClose = true;
          return false;
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error.ip_address) {
          this.message = error.response.data.error['ip_address'];
          // Set the icon to "success" for successful response
          this.icon = "error"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
        } else if (error.response && error.response.data && error.response.data.error.non_field_errors) {
          const errorMessage = error.response.data.error.non_field_errors[0];
          this.message = errorMessage;
          // Set the icon to "success" for successful response
          this.icon = "error"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
        } else if (error.response && error.response.data && error.response.data.error.group) {
          this.message = this.formatErrorMessage(error.response.data.error);
          // Set the icon to "success" for successful response
          this.icon = "error"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
        } else {
          this.message = "Something went wrong. Please try again later.";
          // Set the icon to "success" for successful response
          this.icon = "error"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
        }
      }
    },
    formatErrorMessage(errors) {
      //key and value concinet
      const errorMessages = [];
      for (const [key, value] of Object.entries(errors)) {
        errorMessages.push(`${key} field is required.`);
      }
      return errorMessages.join(' ');
    },

    checkModalClose(event) {
      // Check if modal can be closed
      if (this.preventModalClose) {
        event.preventDefault(); // Prevent the modal from closing
        this.preventModalClose = false; // Reset the flag
      }
    },
    openModal() {
      //popup model open
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();
      this.ipState = null;
      this.groupState = null;
      //     if (this.groupsOptions.length > 0) {
      //   this.newGroup.selectedGroup = this.groupsOptions[0].value;
      // }
          // Add a placeholder option at the beginning of groupsOptions array if it doesn't already exist
        if (!this.groupsOptions.find(option => option.value === null)) {
          this.groupsOptions = [{ text: 'Select Group', value: null }, ...this.groupsOptions];
        }

        // Set selectedGroup to the value of the placeholder option
        this.newGroup.selectedGroup = null;
      if (this.subnet.length > 0) {
        this.newGroup.selectedSubnet = this.subnet[14].value;
      }
    },
    edit(item) {
      const selectedGroupOption = this.groupsOptions.find(option => option.text === item.group);
      this.newGroup = {
        public_id: item.public_id,
        deviceType: item.category, // or the corresponding property from item
        ipAddress: item.ip_address,
        selectedSubnet: item.subnet,
        selectedGroup: selectedGroupOption ? selectedGroupOption.value : null,
        mode: item.mode,
      };
      this.modalOpen = true;
      this.editMode = true;
      this.ipState = null; // Reset IP validation state
      this.groupState = null;

    },
    remove(public_id) {
      //Delete devices
      try {
        let self = this;
        Swal.fire({
          toast: true,
          position: "top",
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const token = localStorage.getItem("token");
            const key = "enc";
            const decryptedToken = decryptToken(token, key);
            axios.delete(
              constant.API_BASE_URL + "vms/instance/" + public_id + "/",
              {
                headers: {
                  Authorization: "Token " + decryptedToken,
                },
              }
            )
              .then(response => {
                this.message = response.data.message
                // Set the icon to "success" for successful response
                this.icon = "success"
                // Call the toastMessage function to display a toast notification
                toastMessage(this.icon, this.message);
                self.fetchData();
                // Do something with the response data if needed
              })
          }
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message;
        } else {
          this.message = "Something went wrong. Please try again later.";
        }
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        // console.error("Error deleting group:", error);
      }
    },


    clearForm() {
      this.newGroup = {
        deviceType: "single",
        sensor_ip: "",
        subnet: "",
        groups: "",
        mode: "normal",
      };
      if (!this.editMode) {
        // Reset or set defaults for 'Add' mode
        this.selectedGroup = [];
        // More default settings can be initialized here
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.newGroup.deviceType = "single";
    });
    this.$forceUpdate();
    this.fetchData(this.currentPage);

    this.fetchGroupOptions();
    core.index();
  },
};
</script>
<style scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 100px;
  height: 50px;
  background: grey;
  display: block;
  border-radius: 50px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 30px;
  transition: 0.3s;
}

input:checked+label {
  background: #bada55;
}

input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 130px;
}
</style>
